import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import { StaticGoogleMap, Marker } from "react-static-google-map";
import { OutboundLink } from "gatsby-plugin-google-analytics";

const Map = ({ map }) => {
  const data = useStaticQuery(
    graphql`
      query MapIcon {
        file(relativePath: { eq: "pin.png" }) {
          publicURL
        }
      }
    `
  );
  const siteUrl =
    process.env.NODE_ENV === "production"
      ? "https://simpsons.com.au"
      : "https://simpsons.com.au";
  const iconUrl = data.file.publicURL;
  return (
    <OutboundLink
      eventCategory="Get Directions"
      action="Click"
      eventLabel="Map Click"
      target="_blank"
      name={map}
      rel="noreferrer"
      href={`https://www.google.com/maps?saddr=My+Location&daddr=${map}`}
    >
      <StaticGoogleMap
        size="500x500"
        apiKey={process.env.GATSBY_GOOGLE_STATIC_MAPS_KEY}
        alt={map}
      >
        <Marker
          location={map}
          size="normal"
          iconURL={siteUrl + iconUrl}
          color="black"
          label="S"
        />
      </StaticGoogleMap>
    </OutboundLink>
  );
};

export default Map;
