import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import EmailLink from "../components/email-link";
import Map from "../components/map";
import SEO from "../components/seo";
import PageHero from "../components/page-hero";
import ContactList from "../components/contact-list";
import Fader from "../helper/Fader";

export const query = graphql`
  {
    allSanitySiteSettings {
      edges {
        node {
          addressOne
          addressThree
          addressTwo
          contactNumber
          generalEmail
          addressInstructions
        }
      }
    }
    allSanityPage(filter: { slug: { current: { eq: "contact" } } }) {
      edges {
        node {
          id
          backgroundImage {
            asset {
              fixed(width: 1600, height: 900) {
                ...GatsbySanityImageFixed_withWebp_noBase64
              }
            }
          }
          pageSEO {
            metaDescription
            metaImage {
              asset {
                url
              }
            }
            metaTitle
          }
          title
          _rawIntro(resolveReferences: { maxDepth: 8 })
        }
      }
    }
  }
`;

class ContactPage extends React.Component {
  render() {
    const contact = this.props.data.allSanitySiteSettings.edges[0].node;
    const page = this.props.data.allSanityPage.edges[0].node;

    return (
      <Layout
        className="page-contact-container"
        active="contact"
        backgroundImage={page.backgroundImage?.asset?.fixed}
      >
        <SEO
          title={(page.pageSEO && page.pageSEO.metaTitle) || page.title}
          description={page.pageSEO && page.pageSEO.metaDescription}
          image={page.pageSEO && page.pageSEO.metaImage}
        />
        <PageHero title={page.title} intro={page._rawIntro} />

        <Fader>
          <section className="general-contact">
            <div className="general-contact-left">
              <h3 className="section-heading">Call us</h3>
              <a href={`tel:${contact.contactNumber}`}>
                {contact.contactNumber}
              </a>
            </div>

            <div className="general-contact-left">
              <h3 className="section-heading">General Enquiries</h3>
              <EmailLink content={contact.generalEmail} />
            </div>
          </section>

          <section className="general-contact">
            <div className="general-contact-left">
              <h3 className="section-heading">
                Looking for a particular lawyer?
              </h3>
              <Link className="specific-cta" to="/people">
                See our people
              </Link>
            </div>
            <div className="general-contact-left">
              <h3 className="section-heading">
                Searching for a lawyer by expertise?
              </h3>
              <Link className="specific-cta" to="/expertise">
                See our expertise
              </Link>
            </div>
          </section>

          <section className="address">
            <h3 className="section-heading">Find us</h3>
            <div className="address-inner">
              <div className="address-details">
                <p className="address-copy">
                  {[
                    contact.addressOne,
                    contact.addressTwo,
                    contact.addressThree,
                  ].map((address, i) => {
                    if (i === 0) {
                      return address;
                    } else {
                      return `\n${address}`;
                    }
                  })}
                </p>
                {contact.addressInstructions && (
                  <p className="address-copy">{contact.addressInstructions}</p>
                )}
              </div>
              <div className="map-container">
                <Map
                  map={`${contact.addressOne} ${contact.addressTwo} ${contact.addressThree}`}
                />
              </div>
            </div>
          </section>
        </Fader>
      </Layout>
    );
  }
}

export default ContactPage;
