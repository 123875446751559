import React from "react";
import { Link } from "gatsby";
import EmailLink from "../components/email-link";

const listItems = item => {
  let path = item.slug.current;

  return item.experts.map((expert, i) => {
    return (
      <li className="contact-list-item" key={i}>
        <Link className="contact-link" to={`/expertise/${path}`}>
          {item.title}
        </Link>
        {expert.email && <EmailLink content={expert.email} />}
      </li>
    );
  });
};

class ContactList extends React.Component {
  render() {
    return (
      <div className="contact-list-items-container">
        {this.props.list && (
          <ul className="contact-list">
            {this.props.list
              .filter(item => item.experts)
              .map((item, i) => item.experts[0] && listItems(item, i))}
          </ul>
        )}
      </div>
    );
  }
}

export default ContactList;
